import "./styles.scoped.css";
export default {
    "dialog": "awsui_dialog_1d2i7_1q6b4_105",
    "modal-slide-up": "awsui_modal-slide-up_1d2i7_1q6b4_1",
    "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1d2i7_1q6b4_1",
    "refresh": "awsui_refresh_1d2i7_1q6b4_128",
    "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_1d2i7_1q6b4_1",
    "root": "awsui_root_1d2i7_1q6b4_161",
    "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_1d2i7_1q6b4_1",
    "hidden": "awsui_hidden_1d2i7_1q6b4_224",
    "focus-lock": "awsui_focus-lock_1d2i7_1q6b4_228",
    "small": "awsui_small_1d2i7_1q6b4_246",
    "medium": "awsui_medium_1d2i7_1q6b4_249",
    "large": "awsui_large_1d2i7_1q6b4_252",
    "max": "awsui_max_1d2i7_1q6b4_255",
    "breakpoint-xs": "awsui_breakpoint-xs_1d2i7_1q6b4_255",
    "container": "awsui_container_1d2i7_1q6b4_260",
    "content": "awsui_content_1d2i7_1q6b4_284",
    "no-paddings": "awsui_no-paddings_1d2i7_1q6b4_287",
    "header": "awsui_header_1d2i7_1q6b4_291",
    "header--text": "awsui_header--text_1d2i7_1q6b4_298",
    "footer": "awsui_footer_1d2i7_1q6b4_302",
    "dismiss-control": "awsui_dismiss-control_1d2i7_1q6b4_312",
    "modal-open": "awsui_modal-open_1d2i7_1q6b4_316"
};
