import "./styles.scoped.css";
export default {
    "root": "awsui_root_18wu0_1xtkt_93",
    "box": "awsui_box_18wu0_1xtkt_205",
    "p-variant": "awsui_p-variant_18wu0_1xtkt_205",
    "color-default": "awsui_color-default_18wu0_1xtkt_205",
    "b-variant": "awsui_b-variant_18wu0_1xtkt_205",
    "strong-variant": "awsui_strong-variant_18wu0_1xtkt_205",
    "code-variant": "awsui_code-variant_18wu0_1xtkt_205",
    "pre-variant": "awsui_pre-variant_18wu0_1xtkt_205",
    "samp-variant": "awsui_samp-variant_18wu0_1xtkt_205",
    "h1-variant": "awsui_h1-variant_18wu0_1xtkt_209",
    "h2-variant": "awsui_h2-variant_18wu0_1xtkt_209",
    "h3-variant": "awsui_h3-variant_18wu0_1xtkt_209",
    "h4-variant": "awsui_h4-variant_18wu0_1xtkt_209",
    "h5-variant": "awsui_h5-variant_18wu0_1xtkt_209",
    "small-variant": "awsui_small-variant_18wu0_1xtkt_213",
    "a-variant": "awsui_a-variant_18wu0_1xtkt_217",
    "font-size-default": "awsui_font-size-default_18wu0_1xtkt_221",
    "font-weight-default": "awsui_font-weight-default_18wu0_1xtkt_261",
    "key-label-variant": "awsui_key-label-variant_18wu0_1xtkt_301",
    "value-large-variant": "awsui_value-large-variant_18wu0_1xtkt_306",
    "color-inverted": "awsui_color-inverted_18wu0_1xtkt_314",
    "color-text-label": "awsui_color-text-label_18wu0_1xtkt_317",
    "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_1xtkt_320",
    "color-text-status-error": "awsui_color-text-status-error_18wu0_1xtkt_323",
    "color-text-status-success": "awsui_color-text-status-success_18wu0_1xtkt_326",
    "color-text-status-info": "awsui_color-text-status-info_18wu0_1xtkt_329",
    "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_1xtkt_332",
    "color-inherit": "awsui_color-inherit_18wu0_1xtkt_335",
    "font-size-body-s": "awsui_font-size-body-s_18wu0_1xtkt_338",
    "font-size-body-m": "awsui_font-size-body-m_18wu0_1xtkt_343",
    "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_1xtkt_347",
    "font-size-heading-s": "awsui_font-size-heading-s_18wu0_1xtkt_351",
    "font-size-heading-m": "awsui_font-size-heading-m_18wu0_1xtkt_356",
    "font-size-heading-l": "awsui_font-size-heading-l_18wu0_1xtkt_361",
    "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_1xtkt_366",
    "font-size-display-l": "awsui_font-size-display-l_18wu0_1xtkt_371",
    "font-weight-light": "awsui_font-weight-light_18wu0_1xtkt_376",
    "font-weight-normal": "awsui_font-weight-normal_18wu0_1xtkt_379",
    "font-weight-bold": "awsui_font-weight-bold_18wu0_1xtkt_382",
    "font-weight-heavy": "awsui_font-weight-heavy_18wu0_1xtkt_385",
    "t-left": "awsui_t-left_18wu0_1xtkt_389",
    "t-right": "awsui_t-right_18wu0_1xtkt_393",
    "t-center": "awsui_t-center_18wu0_1xtkt_397",
    "p-n": "awsui_p-n_18wu0_1xtkt_498",
    "p-top-n": "awsui_p-top-n_18wu0_1xtkt_502",
    "p-vertical-n": "awsui_p-vertical-n_18wu0_1xtkt_503",
    "p-right-n": "awsui_p-right-n_18wu0_1xtkt_507",
    "p-horizontal-n": "awsui_p-horizontal-n_18wu0_1xtkt_508",
    "p-bottom-n": "awsui_p-bottom-n_18wu0_1xtkt_512",
    "p-left-n": "awsui_p-left-n_18wu0_1xtkt_517",
    "p-xxxs": "awsui_p-xxxs_18wu0_1xtkt_522",
    "p-top-xxxs": "awsui_p-top-xxxs_18wu0_1xtkt_526",
    "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_1xtkt_527",
    "p-right-xxxs": "awsui_p-right-xxxs_18wu0_1xtkt_531",
    "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_1xtkt_532",
    "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_1xtkt_536",
    "p-left-xxxs": "awsui_p-left-xxxs_18wu0_1xtkt_541",
    "p-xxs": "awsui_p-xxs_18wu0_1xtkt_546",
    "p-top-xxs": "awsui_p-top-xxs_18wu0_1xtkt_550",
    "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_1xtkt_551",
    "p-right-xxs": "awsui_p-right-xxs_18wu0_1xtkt_555",
    "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_1xtkt_556",
    "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_1xtkt_560",
    "p-left-xxs": "awsui_p-left-xxs_18wu0_1xtkt_565",
    "p-xs": "awsui_p-xs_18wu0_1xtkt_570",
    "p-top-xs": "awsui_p-top-xs_18wu0_1xtkt_574",
    "p-vertical-xs": "awsui_p-vertical-xs_18wu0_1xtkt_575",
    "p-right-xs": "awsui_p-right-xs_18wu0_1xtkt_579",
    "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_1xtkt_580",
    "p-bottom-xs": "awsui_p-bottom-xs_18wu0_1xtkt_584",
    "p-left-xs": "awsui_p-left-xs_18wu0_1xtkt_589",
    "p-s": "awsui_p-s_18wu0_1xtkt_594",
    "p-top-s": "awsui_p-top-s_18wu0_1xtkt_598",
    "p-vertical-s": "awsui_p-vertical-s_18wu0_1xtkt_599",
    "p-right-s": "awsui_p-right-s_18wu0_1xtkt_603",
    "p-horizontal-s": "awsui_p-horizontal-s_18wu0_1xtkt_604",
    "p-bottom-s": "awsui_p-bottom-s_18wu0_1xtkt_608",
    "p-left-s": "awsui_p-left-s_18wu0_1xtkt_613",
    "p-m": "awsui_p-m_18wu0_1xtkt_618",
    "p-top-m": "awsui_p-top-m_18wu0_1xtkt_622",
    "p-vertical-m": "awsui_p-vertical-m_18wu0_1xtkt_623",
    "p-right-m": "awsui_p-right-m_18wu0_1xtkt_627",
    "p-horizontal-m": "awsui_p-horizontal-m_18wu0_1xtkt_628",
    "p-bottom-m": "awsui_p-bottom-m_18wu0_1xtkt_632",
    "p-left-m": "awsui_p-left-m_18wu0_1xtkt_637",
    "p-l": "awsui_p-l_18wu0_1xtkt_517",
    "p-top-l": "awsui_p-top-l_18wu0_1xtkt_646",
    "p-vertical-l": "awsui_p-vertical-l_18wu0_1xtkt_647",
    "p-right-l": "awsui_p-right-l_18wu0_1xtkt_651",
    "p-horizontal-l": "awsui_p-horizontal-l_18wu0_1xtkt_652",
    "p-bottom-l": "awsui_p-bottom-l_18wu0_1xtkt_656",
    "p-left-l": "awsui_p-left-l_18wu0_1xtkt_661",
    "p-xl": "awsui_p-xl_18wu0_1xtkt_666",
    "p-top-xl": "awsui_p-top-xl_18wu0_1xtkt_670",
    "p-vertical-xl": "awsui_p-vertical-xl_18wu0_1xtkt_671",
    "p-right-xl": "awsui_p-right-xl_18wu0_1xtkt_675",
    "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_1xtkt_676",
    "p-bottom-xl": "awsui_p-bottom-xl_18wu0_1xtkt_680",
    "p-left-xl": "awsui_p-left-xl_18wu0_1xtkt_685",
    "p-xxl": "awsui_p-xxl_18wu0_1xtkt_690",
    "p-top-xxl": "awsui_p-top-xxl_18wu0_1xtkt_694",
    "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_1xtkt_695",
    "p-right-xxl": "awsui_p-right-xxl_18wu0_1xtkt_699",
    "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_1xtkt_700",
    "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_1xtkt_704",
    "p-left-xxl": "awsui_p-left-xxl_18wu0_1xtkt_709",
    "p-xxxl": "awsui_p-xxxl_18wu0_1xtkt_714",
    "p-top-xxxl": "awsui_p-top-xxxl_18wu0_1xtkt_718",
    "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_1xtkt_719",
    "p-right-xxxl": "awsui_p-right-xxxl_18wu0_1xtkt_723",
    "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_1xtkt_724",
    "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_1xtkt_728",
    "p-left-xxxl": "awsui_p-left-xxxl_18wu0_1xtkt_733",
    "m-n": "awsui_m-n_18wu0_1xtkt_738",
    "m-top-n": "awsui_m-top-n_18wu0_1xtkt_742",
    "m-vertical-n": "awsui_m-vertical-n_18wu0_1xtkt_743",
    "m-right-n": "awsui_m-right-n_18wu0_1xtkt_747",
    "m-horizontal-n": "awsui_m-horizontal-n_18wu0_1xtkt_748",
    "m-bottom-n": "awsui_m-bottom-n_18wu0_1xtkt_752",
    "m-left-n": "awsui_m-left-n_18wu0_1xtkt_757",
    "m-xxxs": "awsui_m-xxxs_18wu0_1xtkt_762",
    "m-top-xxxs": "awsui_m-top-xxxs_18wu0_1xtkt_766",
    "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_1xtkt_767",
    "m-right-xxxs": "awsui_m-right-xxxs_18wu0_1xtkt_771",
    "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_1xtkt_772",
    "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_1xtkt_776",
    "m-left-xxxs": "awsui_m-left-xxxs_18wu0_1xtkt_781",
    "m-xxs": "awsui_m-xxs_18wu0_1xtkt_786",
    "m-top-xxs": "awsui_m-top-xxs_18wu0_1xtkt_790",
    "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_1xtkt_791",
    "m-right-xxs": "awsui_m-right-xxs_18wu0_1xtkt_795",
    "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_1xtkt_796",
    "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_1xtkt_800",
    "m-left-xxs": "awsui_m-left-xxs_18wu0_1xtkt_805",
    "m-xs": "awsui_m-xs_18wu0_1xtkt_810",
    "m-top-xs": "awsui_m-top-xs_18wu0_1xtkt_814",
    "m-vertical-xs": "awsui_m-vertical-xs_18wu0_1xtkt_815",
    "m-right-xs": "awsui_m-right-xs_18wu0_1xtkt_819",
    "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_1xtkt_820",
    "m-bottom-xs": "awsui_m-bottom-xs_18wu0_1xtkt_824",
    "m-left-xs": "awsui_m-left-xs_18wu0_1xtkt_829",
    "m-s": "awsui_m-s_18wu0_1xtkt_834",
    "m-top-s": "awsui_m-top-s_18wu0_1xtkt_838",
    "m-vertical-s": "awsui_m-vertical-s_18wu0_1xtkt_839",
    "m-right-s": "awsui_m-right-s_18wu0_1xtkt_843",
    "m-horizontal-s": "awsui_m-horizontal-s_18wu0_1xtkt_844",
    "m-bottom-s": "awsui_m-bottom-s_18wu0_1xtkt_848",
    "m-left-s": "awsui_m-left-s_18wu0_1xtkt_853",
    "m-m": "awsui_m-m_18wu0_1xtkt_858",
    "m-top-m": "awsui_m-top-m_18wu0_1xtkt_862",
    "m-vertical-m": "awsui_m-vertical-m_18wu0_1xtkt_863",
    "m-right-m": "awsui_m-right-m_18wu0_1xtkt_867",
    "m-horizontal-m": "awsui_m-horizontal-m_18wu0_1xtkt_868",
    "m-bottom-m": "awsui_m-bottom-m_18wu0_1xtkt_872",
    "m-left-m": "awsui_m-left-m_18wu0_1xtkt_877",
    "m-l": "awsui_m-l_18wu0_1xtkt_757",
    "m-top-l": "awsui_m-top-l_18wu0_1xtkt_886",
    "m-vertical-l": "awsui_m-vertical-l_18wu0_1xtkt_887",
    "m-right-l": "awsui_m-right-l_18wu0_1xtkt_891",
    "m-horizontal-l": "awsui_m-horizontal-l_18wu0_1xtkt_892",
    "m-bottom-l": "awsui_m-bottom-l_18wu0_1xtkt_896",
    "m-left-l": "awsui_m-left-l_18wu0_1xtkt_901",
    "m-xl": "awsui_m-xl_18wu0_1xtkt_906",
    "m-top-xl": "awsui_m-top-xl_18wu0_1xtkt_910",
    "m-vertical-xl": "awsui_m-vertical-xl_18wu0_1xtkt_911",
    "m-right-xl": "awsui_m-right-xl_18wu0_1xtkt_915",
    "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_1xtkt_916",
    "m-bottom-xl": "awsui_m-bottom-xl_18wu0_1xtkt_920",
    "m-left-xl": "awsui_m-left-xl_18wu0_1xtkt_925",
    "m-xxl": "awsui_m-xxl_18wu0_1xtkt_930",
    "m-top-xxl": "awsui_m-top-xxl_18wu0_1xtkt_934",
    "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_1xtkt_935",
    "m-right-xxl": "awsui_m-right-xxl_18wu0_1xtkt_939",
    "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_1xtkt_940",
    "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_1xtkt_944",
    "m-left-xxl": "awsui_m-left-xxl_18wu0_1xtkt_949",
    "m-xxxl": "awsui_m-xxxl_18wu0_1xtkt_954",
    "m-top-xxxl": "awsui_m-top-xxxl_18wu0_1xtkt_958",
    "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_1xtkt_959",
    "m-right-xxxl": "awsui_m-right-xxxl_18wu0_1xtkt_963",
    "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_1xtkt_964",
    "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_1xtkt_968",
    "m-left-xxxl": "awsui_m-left-xxxl_18wu0_1xtkt_973",
    "d-block": "awsui_d-block_18wu0_1xtkt_978",
    "d-inline": "awsui_d-inline_18wu0_1xtkt_981",
    "d-inline-block": "awsui_d-inline-block_18wu0_1xtkt_984",
    "d-none": "awsui_d-none_18wu0_1xtkt_987",
    "f-left": "awsui_f-left_18wu0_1xtkt_991",
    "f-right": "awsui_f-right_18wu0_1xtkt_995"
};
