import "./styles.scoped.css";
export default {
    "icon": "awsui_icon_k5dlb_euv96_93",
    "top-navigation": "awsui_top-navigation_k5dlb_euv96_111",
    "padding-box": "awsui_padding-box_k5dlb_euv96_122",
    "medium": "awsui_medium_k5dlb_euv96_132",
    "narrow": "awsui_narrow_k5dlb_euv96_132",
    "virtual": "awsui_virtual_k5dlb_euv96_143",
    "hidden": "awsui_hidden_k5dlb_euv96_147",
    "	": "awsui_	_k5dlb_euv96_1",
    "identity": "awsui_identity_k5dlb_euv96_159",
    "identity-link": "awsui_identity-link_k5dlb_euv96_162",
    "no-logo": "awsui_no-logo_k5dlb_euv96_179",
    "logo": "awsui_logo_k5dlb_euv96_183",
    "title": "awsui_title_k5dlb_euv96_195",
    "inputs": "awsui_inputs_k5dlb_euv96_208",
    "search": "awsui_search_k5dlb_euv96_215",
    "search-expanded": "awsui_search-expanded_k5dlb_euv96_219",
    "utilities": "awsui_utilities_k5dlb_euv96_223",
    "utility-wrapper": "awsui_utility-wrapper_k5dlb_euv96_234",
    "utility-type-button-link": "awsui_utility-type-button-link_k5dlb_euv96_255",
    "utility-type-menu-dropdown": "awsui_utility-type-menu-dropdown_k5dlb_euv96_259",
    "utility-type-button-primary-button": "awsui_utility-type-button-primary-button_k5dlb_euv96_267",
    "utility-link-icon": "awsui_utility-link-icon_k5dlb_euv96_275",
    "utility-button-external-icon": "awsui_utility-button-external-icon_k5dlb_euv96_279",
    "offset-right-none": "awsui_offset-right-none_k5dlb_euv96_283",
    "offset-right-l": "awsui_offset-right-l_k5dlb_euv96_287",
    "offset-right-xxl": "awsui_offset-right-xxl_k5dlb_euv96_291",
    "overflow-menu-drawer": "awsui_overflow-menu-drawer_k5dlb_euv96_296",
    "overflow-menu": "awsui_overflow-menu_k5dlb_euv96_296",
    "overflow-menu-header": "awsui_overflow-menu-header_k5dlb_euv96_316",
    "overflow-menu-header-text": "awsui_overflow-menu-header-text_k5dlb_euv96_323",
    "overflow-menu-header-text--secondary": "awsui_overflow-menu-header-text--secondary_k5dlb_euv96_332",
    "overflow-menu-header-text--title": "awsui_overflow-menu-header-text--title_k5dlb_euv96_338",
    "overflow-menu-back-button": "awsui_overflow-menu-back-button_k5dlb_euv96_342",
    "overflow-menu-dismiss-button": "awsui_overflow-menu-dismiss-button_k5dlb_euv96_346",
    "overflow-menu-control": "awsui_overflow-menu-control_k5dlb_euv96_350",
    "overflow-menu-list-item-utility": "awsui_overflow-menu-list-item-utility_k5dlb_euv96_365",
    "overflow-menu-control-link": "awsui_overflow-menu-control-link_k5dlb_euv96_372",
    "overflow-menu-control-expandable-menu-trigger": "awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_euv96_375",
    "overflow-menu-list": "awsui_overflow-menu-list_k5dlb_euv96_365",
    "overflow-menu-list-submenu": "awsui_overflow-menu-list-submenu_k5dlb_euv96_406",
    "overflow-menu-list-item": "awsui_overflow-menu-list-item_k5dlb_euv96_365",
    "overflow-menu-list-item-icon": "awsui_overflow-menu-list-item-icon_k5dlb_euv96_414",
    "overflow-menu-list-item-text": "awsui_overflow-menu-list-item-text_k5dlb_euv96_417",
    "overflow-menu-list-item-submenu": "awsui_overflow-menu-list-item-submenu_k5dlb_euv96_424",
    "overflow-menu-list-item-dropdown-menu": "awsui_overflow-menu-list-item-dropdown-menu_k5dlb_euv96_429",
    "overflow-menu-list-item-expandable": "awsui_overflow-menu-list-item-expandable_k5dlb_euv96_432",
    "expanded": "awsui_expanded_k5dlb_euv96_440"
};
