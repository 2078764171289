import "./styles.scoped.css";
export default {
    "button": "awsui_button_m5h9f_10xh9_93",
    "expanded": "awsui_expanded_m5h9f_10xh9_123",
    "offset-right-none": "awsui_offset-right-none_m5h9f_10xh9_134",
    "offset-right-l": "awsui_offset-right-l_m5h9f_10xh9_137",
    "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_10xh9_140",
    "text": "awsui_text_m5h9f_10xh9_162",
    "icon": "awsui_icon_m5h9f_10xh9_166"
};
