import "./styles.scoped.css";
export default {
    "icon": "awsui_icon_h11ix_k54ie_98",
    "icon-flex-height": "awsui_icon-flex-height_h11ix_k54ie_104",
    "size-small": "awsui_size-small_h11ix_k54ie_129",
    "size-small-mapped-height": "awsui_size-small-mapped-height_h11ix_k54ie_133",
    "size-normal": "awsui_size-normal_h11ix_k54ie_147",
    "size-normal-mapped-height": "awsui_size-normal-mapped-height_h11ix_k54ie_151",
    "size-medium": "awsui_size-medium_h11ix_k54ie_165",
    "size-medium-mapped-height": "awsui_size-medium-mapped-height_h11ix_k54ie_169",
    "size-big": "awsui_size-big_h11ix_k54ie_183",
    "size-big-mapped-height": "awsui_size-big-mapped-height_h11ix_k54ie_187",
    "size-large": "awsui_size-large_h11ix_k54ie_201",
    "size-large-mapped-height": "awsui_size-large-mapped-height_h11ix_k54ie_205",
    "variant-normal": "awsui_variant-normal_h11ix_k54ie_219",
    "variant-disabled": "awsui_variant-disabled_h11ix_k54ie_222",
    "variant-inverted": "awsui_variant-inverted_h11ix_k54ie_225",
    "variant-subtle": "awsui_variant-subtle_h11ix_k54ie_228",
    "variant-warning": "awsui_variant-warning_h11ix_k54ie_231",
    "variant-error": "awsui_variant-error_h11ix_k54ie_234",
    "variant-success": "awsui_variant-success_h11ix_k54ie_237",
    "variant-link": "awsui_variant-link_h11ix_k54ie_240",
    "badge": "awsui_badge_h11ix_k54ie_244"
};
