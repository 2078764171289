import "./styles.scoped.css";
export default {
    "arrow": "awsui_arrow_xjuzf_10pru_185",
    "arrow-outer": "awsui_arrow-outer_xjuzf_10pru_189",
    "arrow-inner": "awsui_arrow-inner_xjuzf_10pru_189",
    "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_10pru_220",
    "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_10pru_220",
    "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_10pru_223",
    "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_10pru_223",
    "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_10pru_226",
    "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_10pru_226",
    "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_10pru_226",
    "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_10pru_226",
    "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_10pru_229",
    "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_10pru_229",
    "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_10pru_229",
    "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_10pru_229",
    "body": "awsui_body_xjuzf_10pru_325",
    "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_10pru_339",
    "has-dismiss": "awsui_has-dismiss_xjuzf_10pru_343",
    "dismiss": "awsui_dismiss_xjuzf_10pru_348",
    "dismiss-control": "awsui_dismiss-control_xjuzf_10pru_355",
    "header-row": "awsui_header-row_xjuzf_10pru_359",
    "header": "awsui_header_xjuzf_10pru_359",
    "content": "awsui_content_xjuzf_10pru_381",
    "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_10pru_388",
    "container": "awsui_container_xjuzf_10pru_484",
    "container-body": "awsui_container-body_xjuzf_10pru_492",
    "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_10pru_507",
    "container-body-size-small": "awsui_container-body-size-small_xjuzf_10pru_512",
    "fixed-width": "awsui_fixed-width_xjuzf_10pru_515",
    "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_10pru_519",
    "container-body-size-large": "awsui_container-body-size-large_xjuzf_10pru_526",
    "container-arrow": "awsui_container-arrow_xjuzf_10pru_538",
    "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_10pru_542",
    "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_10pru_542",
    "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_10pru_554",
    "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_10pru_554",
    "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_10pru_566",
    "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_10pru_566",
    "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_10pru_566",
    "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_10pru_566",
    "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_10pru_582",
    "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_10pru_586",
    "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_10pru_590",
    "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_10pru_1",
    "refresh": "awsui_refresh_xjuzf_10pru_708",
    "root": "awsui_root_xjuzf_10pru_730",
    "trigger": "awsui_trigger_xjuzf_10pru_743",
    "trigger-type-text": "awsui_trigger-type-text_xjuzf_10pru_750",
    "trigger-inner-text": "awsui_trigger-inner-text_xjuzf_10pru_779",
    "popover-content": "awsui_popover-content_xjuzf_10pru_783"
};
