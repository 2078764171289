import logo from "../public/firekit.svg";
import {useState} from "react";
import {Input, TopNavigation} from "@cloudscape-design/components-themed";


const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

export function TopNav() {

  const profileActions = [
    {type: 'button', id: 'profile', text: 'Profile'},
    {type: 'button', id: 'preferences', text: 'Preferences'},
    {type: 'button', id: 'security', text: 'Security'},
    {
      type: 'menu-dropdown',
      id: 'support-group',
      text: 'Support',
      items: [
        {
          id: 'documentation',
          text: 'Documentation',
          href: '#',
          external: true,
          externalIconAriaLabel: ' (opens in new tab)',
        },
        {id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)'},
        {id: 'support', text: 'Customer support'},
      ],
    },
    {type: 'button', id: 'signout', text: 'Sign out'},
  ];

  const [searchValue, setSearchValue] = useState("");

  return (
    <TopNavigation
      i18nStrings={i18nStrings}
      identity={{
        href: '#',
        title: 'firekit',
        logo: {src: logo.src, alt: 'firekit logo'},
      }}
      search={
        <Input
          ariaLabel="Input field"
          value={searchValue}
          type="search"
          placeholder="Search"
          onChange={({detail}) => setSearchValue(detail.value)}
        />
      }
      utilities={[
        {
          type: 'button',
          iconName: 'notification',
          ariaLabel: 'Notifications',
          badge: true,
          disableUtilityCollapse: true,
        },
        {type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings'},
        {
          type: 'menu-dropdown',
          text: 'Customer name',
          description: 'customer@example.com',
          iconName: 'user-profile',
          items: profileActions,
        },
      ]}
    />
  )
}