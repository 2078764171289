import "./styles.scoped.css";
export default {
    "content": "awsui_content_vjswe_19wtj_97",
    "button": "awsui_button_vjswe_19wtj_101",
    "variant-normal": "awsui_variant-normal_vjswe_19wtj_122",
    "is-activated": "awsui_is-activated_vjswe_19wtj_135",
    "variant-icon": "awsui_variant-icon_vjswe_19wtj_162",
    "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_19wtj_162",
    "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_19wtj_162",
    "variant-inline-icon": "awsui_variant-inline-icon_vjswe_19wtj_180",
    "disabled": "awsui_disabled_vjswe_19wtj_198",
    "variant-primary": "awsui_variant-primary_vjswe_19wtj_206",
    "variant-link": "awsui_variant-link_vjswe_19wtj_290",
    "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_19wtj_710",
    "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_19wtj_797",
    "button-no-text": "awsui_button-no-text_vjswe_19wtj_881",
    "button-no-wrap": "awsui_button-no-wrap_vjswe_19wtj_885",
    "icon-left": "awsui_icon-left_vjswe_19wtj_901",
    "icon-right": "awsui_icon-right_vjswe_19wtj_906",
    "icon": "awsui_icon_vjswe_19wtj_901"
};
