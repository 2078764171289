import "./styles.scoped.css";
export default {
    "content-enter": "awsui_content-enter_gwq0h_19ecb_97",
    "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_gwq0h_19ecb_1",
    "trigger-expanded": "awsui_trigger-expanded_gwq0h_19ecb_119",
    "icon": "awsui_icon_gwq0h_19ecb_133",
    "root": "awsui_root_gwq0h_19ecb_151",
    "expanded": "awsui_expanded_gwq0h_19ecb_169",
    "icon-container": "awsui_icon-container_gwq0h_19ecb_173",
    "trigger": "awsui_trigger_gwq0h_19ecb_119",
    "trigger-default": "awsui_trigger-default_gwq0h_19ecb_188",
    "trigger-footer": "awsui_trigger-footer_gwq0h_19ecb_191",
    "trigger-navigation": "awsui_trigger-navigation_gwq0h_19ecb_197",
    "trigger-container": "awsui_trigger-container_gwq0h_19ecb_209",
    "header": "awsui_header_gwq0h_19ecb_219",
    "header-container": "awsui_header-container_gwq0h_19ecb_222",
    "header-container-button": "awsui_header-container-button_gwq0h_19ecb_238",
    "header-navigation": "awsui_header-navigation_gwq0h_19ecb_246",
    "content": "awsui_content_gwq0h_19ecb_97",
    "content-default": "awsui_content-default_gwq0h_19ecb_282",
    "content-footer": "awsui_content-footer_gwq0h_19ecb_285",
    "content-expanded": "awsui_content-expanded_gwq0h_19ecb_288",
    "focusable": "awsui_focusable_gwq0h_19ecb_292"
};
