import "./styles.scoped.css";
export default {
    "root": "awsui_root_2qdw9_1s542_93",
    "root-no-actions": "awsui_root-no-actions_2qdw9_1s542_108",
    "root-variant-h1": "awsui_root-variant-h1_2qdw9_1s542_108",
    "root-variant-h1-refresh": "awsui_root-variant-h1-refresh_2qdw9_1s542_111",
    "root-variant-h2": "awsui_root-variant-h2_2qdw9_1s542_114",
    "root-has-description": "awsui_root-has-description_2qdw9_1s542_114",
    "root-variant-h2-refresh": "awsui_root-variant-h2-refresh_2qdw9_1s542_117",
    "root-variant-h3": "awsui_root-variant-h3_2qdw9_1s542_120",
    "root-variant-h3-refresh": "awsui_root-variant-h3-refresh_2qdw9_1s542_123",
    "root-no-wrap": "awsui_root-no-wrap_2qdw9_1s542_126",
    "main": "awsui_main_2qdw9_1s542_130",
    "main-variant-h1": "awsui_main-variant-h1_2qdw9_1s542_138",
    "main-variant-h1-refresh": "awsui_main-variant-h1-refresh_2qdw9_1s542_141",
    "main-variant-h2": "awsui_main-variant-h2_2qdw9_1s542_147",
    "main-variant-h3": "awsui_main-variant-h3_2qdw9_1s542_147",
    "main-variant-h2-refresh": "awsui_main-variant-h2-refresh_2qdw9_1s542_153",
    "actions": "awsui_actions_2qdw9_1s542_160",
    "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_1s542_164",
    "actions-variant-h1-refresh": "awsui_actions-variant-h1-refresh_2qdw9_1s542_167",
    "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_1s542_170",
    "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_1s542_170",
    "actions-variant-h2-refresh": "awsui_actions-variant-h2-refresh_2qdw9_1s542_176",
    "actions-variant-h3-refresh": "awsui_actions-variant-h3-refresh_2qdw9_1s542_179",
    "title": "awsui_title_2qdw9_1s542_183",
    "title-variant-h2": "awsui_title-variant-h2_2qdw9_1s542_189",
    "title-variant-h3": "awsui_title-variant-h3_2qdw9_1s542_189",
    "title-variant-h2-refresh": "awsui_title-variant-h2-refresh_2qdw9_1s542_198",
    "info": "awsui_info_2qdw9_1s542_208",
    "description": "awsui_description_2qdw9_1s542_212",
    "description-variant-h1": "awsui_description-variant-h1_2qdw9_1s542_217",
    "description-variant-h1-refresh": "awsui_description-variant-h1-refresh_2qdw9_1s542_222",
    "description-variant-h2": "awsui_description-variant-h2_2qdw9_1s542_225",
    "description-variant-h3": "awsui_description-variant-h3_2qdw9_1s542_230",
    "description-variant-h3-refresh": "awsui_description-variant-h3-refresh_2qdw9_1s542_236",
    "heading": "awsui_heading_2qdw9_1s542_240",
    "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_1s542_249",
    "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_1s542_255",
    "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_1s542_259",
    "heading-text": "awsui_heading-text_2qdw9_1s542_264",
    "counter": "awsui_counter_2qdw9_1s542_268"
};
