export default {
    "add-plus": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8 1v14M15 8H1"/></svg>',
    "angle-down": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m1 4 7 7 7-7"/></svg>',
    "angle-left-double": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M14 1 7 8l7 7"/><path d="M9 1 2 8l7 7"/></svg>',
    "angle-left": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M12 1 5 8l7 7"/></svg>',
    "angle-right-double": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m2 1 7 7-7 7"/><path d="m7 1 7 7-7 7"/></svg>',
    "angle-right": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m4 1 7 7-7 7"/></svg>',
    "angle-up": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m1 12 7-7 7 7"/></svg>',
    "arrow-left": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 1 2 8l7 7M2 8h13"/></svg>',
    "audio-full": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M7 15V1L1 6v4l6 5ZM15 3v10M11 5v6"/></svg>',
    "audio-half": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M7 15V1L1 6v4l6 5ZM11 5v6"/></svg>',
    "audio-off": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M7 15V1L1 6v4l6 5Z"/><path d="m15.01 5.75-4.5 4.5M15.01 10.25l-4.5-4.5"/></svg>',
    "bug": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M5 5h6a1 1 0 0 1 1 1v5.5A3.5 3.5 0 0 1 8.5 15h-1A3.5 3.5 0 0 1 4 11.5V6a1 1 0 0 1 1-1ZM5 5a3 3 0 0 1 6 0"/><path d="M12 9h3M1 9h3M12 12h2l1 2M4 12H2l-1 2M12 6h2l1-2M4 6H2L1 4"/></svg>',
    "calendar": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M2 2h12v12H2z"/><path d="M4.99 8H5v.01h-.01zM7.99 8H8v.01h-.01zM10.99 8H11v.01h-.01zM4.99 5H5v.01h-.01zM7.99 5H8v.01h-.01zM10.99 5H11v.01h-.01zM4.99 11H5v.01h-.01zM7.99 11H8v.01h-.01z"/></svg>',
    "call": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 2c2.8 0 5 2.2 5 5M7.9 13c1.3 1.3 3.5 1.3 4.8 0l1-1c.4-.4.4-1 0-1.4l-1.5-1.5c-.3-.3-1-.2-1.3.1l-.1.1c-.5.5-1.4.5-2 0L6.6 7.2c-.5-.5-.5-1.4 0-2l.1-.1c.3-.3.4-1 .1-1.3L5.3 2.3c-.3-.4-1-.4-1.3 0l-1 1C1.7 4.6 1.7 6.7 3 8.1L7.9 13z"/></svg>',
    "caret-down-filled": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"/></svg>',
    "caret-down": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"/></svg>',
    "caret-left-filled": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M11 4v8L5 8l6-4z"/></svg>',
    "caret-right-filled": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M5 4v8l6-4-6-4z"/></svg>',
    "caret-up-filled": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M4 11h8L8 5l-4 6z"/></svg>',
    "caret-up": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M4 11h8L8 5l-4 6z"/></svg>',
    "check": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m14.254 4.254-8.25 8.25L6 12.5l-.004.004-4.25-4.25.008-.008L6 12.493l8.246-8.247.008.008Z"/></svg>',
    "close": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="m2 2 12 12M14 2 2 14"/></svg>',
    "contact": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round stroke-linecap-round" d="M2 12.286h5.143L8.857 14l1.714-1.714H14V2H2v10.286z"/><path class="stroke-linecap-round" d="M4.99 7H5v.01h-.01zM7.99 7H8v.01h-.01zM10.99 7H11v.01h-.01z"/></svg>',
    "copy": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M2 5h9v9H2z"/><path class="stroke-linejoin-round" d="M5 5V2h9v9h-3"/></svg>',
    "delete-marker": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M3 8V1h6l5 5v9H9"/><path d="M8 1v6h6"/><path class="stroke-linejoin-round" d="m6.87 14.38-4.24-4.25M2.63 14.38l4.24-4.25"/></svg>',
    "download": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M11 2h3v12H2V2h3"/><path d="m4 6 4 4 4-4M8 1v9"/></svg>',
    "edit": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M13 8v6H3V2h6.5M6 10l8-8"/></svg>',
    "ellipsis": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="filled" cx="8" cy="2.5" r=".5"/><circle class="filled" cx="8" cy="8" r=".5"/><circle class="filled" cx="8" cy="13.5" r=".5"/></svg>',
    "envelope": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M1 2h14v12H1z"/><path class="stroke-linejoin-round" d="m1 4 7 6 7-6"/></svg>',
    "expand": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M9 2h5v5M7 2H2v5M7 14H2V9M9 14h5V9M2 2l12 12M14 2 2 14"/></svg>',
    "external": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linecap-square" d="M10 2h4v4"/><path d="m6 10 8-8"/><path class="stroke-linejoin-round" d="M14 9.048V14H2V2h5"/></svg>',
    "file-open": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M8 1v4a1 1 0 0 0 1 1h4"/><path class="stroke-linejoin-round" d="M3 15h10V5L9 1H3v14z"/><path d="m3 8 7 7"/></svg>',
    "file": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M8 1v5h5"/><path class="stroke-linejoin-round" d="M3 15V1h6l4 4v10H3z"/></svg>',
    "filter": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M15 2H1l5 6v7h4V8l5-6z"/></svg>',
    "flag": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="m2.71 9.18 1.11-.39C5.2 8.3 6.74 8.44 8 9.18c1.27.74 2.8.89 4.18.39l1.11-.39v-6l-1.11.39c-1.38.49-2.92.35-4.18-.39-1.27-.74-2.8-.89-4.18-.39l-1.11.39v11.83"/></svg>',
    "folder-open": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M2 7V2h7l1 2h5v9a1 1 0 0 1-1 1H3L1 7h10l2.006 7"/></svg>',
    "folder": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M14 14H2V2h5.143L9 5h5v9z"/></svg>',
    "group-active": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M14 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM9 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3Z"/><path class="filled stroke-linejoin-round" d="M6 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM1 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3Z"/></svg>',
    "group": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M14 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM9 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3ZM6 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM1 11v3h6v-3c0-1.66-1.34-3-3-3s-3 1.34-3 3Z"/></svg>',
    "heart": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M13.92 3.061a3.729 3.729 0 0 0-5.21 0L8 3.76l-.71-.699a3.729 3.729 0 0 0-5.21 0 3.58 3.58 0 0 0 0 5.123l5.21 5.124.003-.004L8 14l.707-.696.004.004 5.21-5.124a3.58 3.58 0 0 0 0-5.123Z"/></svg>',
    "insert-row": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M15 6V3H1v9h5M16 11H8M12 15V7"/></svg>',
    "key": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M9 10a5.023 5.023 0 0 1 0 1 3.996 3.996 0 1 1-3-3.874L13 1h2v5h-2v2h-2l.016 1.983Z"/><path d="M4.99 11H5v.01h-.01z"/></svg>',
    "keyboard": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M15 2H1v12h14V2Z"/><path d="M6 5H4M9 5H7M12 5h-2M6 8H4M12 11H4M9 8H7M12 8h-2"/></svg>',
    "lock-private": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M2 7h12v7H2z"/><path d="M4 7V5a4 4 0 0 1 8 0v2"/></svg>',
    "menu": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M15 8H1M15 3H1M15 13H1"/></svg>',
    "microphone-off": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M13.76 1.34 2.11 12.98M4.75 10.8A5.002 5.002 0 0 0 13 7V6M8 15v-3M3 15h10"/><path class="filled" d="M8 1c-1.1 0-2 .9-2 2v1.09l2.88-2.88C8.61 1.08 8.32 1 8 1ZM6.64 8.46A2 2 0 0 0 8 9c1.1 0 2-.9 2-2V5.09L6.64 8.45v.01Z"/><path class="stroke-linejoin-round" d="M3.37 8.53C3.024 8.184 3 7.537 3 7V6"/></svg>',
    "microphone": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><rect class="stroke-linejoin-round" x="6" y="1" width="4" height="8" rx="2"/><path class="stroke-linejoin-round" d="M3 6v1a5 5 0 0 0 10 0V6M8 12v3M3 15h10"/></svg>',
    "multiscreen": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M11 5H1v7h10V5Z"/><path class="stroke-linejoin-round" d="M11 8.229h4v-7H5V5M1 15h10M6 15v-3"/></svg>',
    "notification": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M8 2.167c-3.5 0-3.5 4.666-3.5 4.666L1 11.5h14l-3.5-4.667s0-4.666-3.5-4.666ZM5.667 11.5v1.167a2.333 2.333 0 0 0 4.666 0V11.5"/><path class="stroke-linecap-round" d="M8 1v1.167"/></svg>',
    "redo": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M12 5H6.38c-4.5 0-4.5 8 0 8H13"/><path class="stroke-linejoin-round" d="m10 8 3-3-3-3"/></svg>',
    "refresh": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M10 5h5V0"/><path d="M15 8a6.957 6.957 0 0 1-7 7 6.957 6.957 0 0 1-7-7 6.957 6.957 0 0 1 7-7 6.87 6.87 0 0 1 6.3 4"/></svg>',
    "remove": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="m3 3 1 11h8l1-11H3ZM1 3h14M10 2H6v1h4V2Z"/></svg>',
    "script": '<svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="m9.53 4.397-3.06 7.4M4.5 11.597 1 8.097l3.5-3.5M11.5 11.597l3.5-3.5-3.5-3.5"/></svg>',
    "search": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="7" cy="7" r="5"/><path d="m15 15-4.5-4.5"/></svg>',
    "security": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M8 14.83s-6-1.925-6-6.74V3.265c3.31 0 5.99-1.916 5.99-1.916s2.7 1.916 6.01 1.916V8.09c0 4.815-6 6.74-6 6.74Z"/><path d="M2 8.09V3.265c3.31 0 5.99-1.916 5.99-1.916M13.99 8.09V3.265C10.68 3.265 8 1.35 8 1.35"/><path class="stroke-linejoin-round" d="M8 4v5M8 10v2"/></svg>',
    "settings": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path d="M13.33 5.792a1.942 1.942 0 0 1 .287-1.97 6.984 6.984 0 0 0-1.44-1.439 1.943 1.943 0 0 1-3.159-1.308 6.965 6.965 0 0 0-2.037 0 1.943 1.943 0 0 1-3.158 1.308 6.962 6.962 0 0 0-1.44 1.44 1.943 1.943 0 0 1-1.308 3.158 6.972 6.972 0 0 0 0 2.037 1.943 1.943 0 0 1 1.308 3.159 6.952 6.952 0 0 0 1.44 1.44 1.942 1.942 0 0 1 3.159 1.308 6.962 6.962 0 0 0 2.036 0 1.942 1.942 0 0 1 3.159-1.308 6.974 6.974 0 0 0 1.44-1.44 1.943 1.943 0 0 1 1.308-3.159 6.974 6.974 0 0 0 0-2.037 1.942 1.942 0 0 1-1.596-1.189Z"/><circle cx="8" cy="8" r="2"/></svg>',
    "share": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="11" cy="4" r="2"/><circle cx="4" cy="8" r="2"/><circle cx="11" cy="12" r="2"/><path d="M9.2 4.9 5.8 7.1M9.2 11.1 5.8 8.9"/></svg>',
    "shrink": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M10 1v5h5M10 6l5-5M6 15v-5H1M6 10l-5 5"/></svg>',
    "status-in-progress": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="stroke-linejoin-round" cx="8" cy="8" r="7"/><path class="stroke-linecap-round" d="M4.99 7.995H5v.01h-.01zM7.99 7.995H8v.01h-.01zM10.99 7.995H11v.01h-.01z"/></svg>',
    "status-info": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="stroke-linejoin-round" cx="8" cy="8" r="7"/><path d="M8 11V8H6"/><path class="stroke-linejoin-round" d="M10 11H6"/><path d="M7.99 5H8v.01h-.01z"/></svg>',
    "status-negative": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="stroke-linejoin-round" cx="8" cy="8" r="7"/><path d="m10.828 5.172-5.656 5.656M10.828 10.828 5.172 5.172"/></svg>',
    "status-pending": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="stroke-linejoin-round" cx="8" cy="8" r="7"/><path class="stroke-linecap-square" d="M8 5v4H5"/></svg>',
    "status-positive": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="stroke-linejoin-round" cx="8" cy="8" r="7"/><path class="stroke-linecap-square" d="m5 8 2 2 3.521-3.521"/></svg>',
    "status-stopped": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle class="stroke-linejoin-round" cx="8" cy="8" r="7"/><path class="stroke-linecap-square" d="M11 8H5"/></svg>',
    "status-warning": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="m8 1 7 14H1L8 1z"/><path d="M7.99 12H8v.01h-.01zM8 6v4"/></svg>',
    "suggestions": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M6 14h4"/><path d="M8 11h1c0-1.53 3-2.46 3-5.24.02-1.36-.61-2.4-1.57-3.09a4.208 4.208 0 0 0-4.86 0C4.61 3.36 3.98 4.4 4 5.76 4 8.54 7 9.47 7 11h1Z"/></svg>',
    "thumbs-down-filled": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M6 9.58c0 .27.06.55.19.79l1.64 3.28c.1.21.32.34.55.34.34 0 .62-.28.62-.62v-3.33h3.44a2 2 0 0 0 1.94-2.48l-1.01-4.05a2 2 0 0 0-1.94-1.52H6v7.59Z"/><path class="stroke-linejoin-round" d="M6 2H2v7h4"/></svg>',
    "thumbs-down": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M6 9.58c0 .27.06.55.19.79l1.64 3.28c.1.21.32.34.55.34.34 0 .62-.28.62-.62v-3.33h3.44a2 2 0 0 0 1.94-2.48l-1.01-4.05a2 2 0 0 0-1.94-1.52H6v7.59ZM6 2H2v7h4"/></svg>',
    "thumbs-up-filled": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M6 6.42c0-.27.06-.55.19-.79l1.64-3.28c.1-.21.32-.34.55-.34.34 0 .62.28.62.62v3.33h3.44a2 2 0 0 1 1.94 2.48l-1.01 4.05a2 2 0 0 1-1.94 1.52H6V6.42Z"/><path class="stroke-linejoin-round" d="M6 14H2V7h4"/></svg>',
    "thumbs-up": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M6 6.42c0-.27.06-.55.19-.79l1.64-3.28c.1-.21.32-.34.55-.34.34 0 .62.28.62.62v3.33h3.44a2 2 0 0 1 1.94 2.48l-1.01 4.05a2 2 0 0 1-1.94 1.52H6V6.42ZM6 14H2V7h4"/></svg>',
    "ticket": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M8 4.275a2 2 0 0 1-2-2H3v12h3a2 2 0 1 1 4 0h3v-12h-3a2 2 0 0 1-2 2Z"/></svg>',
    "treeview-collapse": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M5 8h6M2 2h12v12H2z"/></svg>',
    "treeview-expand": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M5 8h6M8 11V5M2 2h12v12H2z"/></svg>',
    "undo": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M4 13h5.625c4.5 0 4.5-8 0-8H3"/><path class="stroke-linejoin-round" d="M6 2 3 5l3 3"/></svg>',
    "unlocked": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M2 7h12v7H2z"/><path d="M11.874 4A4 4 0 0 0 4 5v2"/></svg>',
    "upload-download": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 13.701v-10M8 6.702l3-3 3 3M4.98 2.662v10.04M7.98 9.662l-3 3-3-3"/></svg>',
    "upload": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M5 14H2V2h12v12h-3"/><path d="M12 10 8 6l-4 4M8 6v9"/></svg>',
    "user-profile-active": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled stroke-linejoin-round" d="M4.61 4.39C4.61 2.52 6.13 1 8 1s3.39 1.52 3.39 3.39S9.87 7.78 8 7.78 4.61 6.26 4.61 4.39M2.05 14.08h11.91c0-2.25-1.17-4.23-2.9-5.3A5.77 5.77 0 0 0 8 7.91c-3.29 0-5.95 2.76-5.95 6.17z"/></svg>',
    "user-profile": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M4.61 4.39C4.61 2.52 6.13 1 8 1s3.39 1.52 3.39 3.39S9.87 7.78 8 7.78 4.61 6.26 4.61 4.39M2.05 14.08h11.91c0-2.25-1.17-4.23-2.9-5.3A5.77 5.77 0 0 0 8 7.91c-3.29 0-5.95 2.76-5.95 6.17z"/></svg>',
    "video-off": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M3 15h10M8 15v-3M8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10ZM6 5l4 4M10 5 6 9"/></svg>',
    "video-on": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M3 15h10M8 15v-3M8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10Z"/><path class="stroke-linejoin-round" d="M8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/></svg>',
    "video-unavailable": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path class="stroke-linejoin-round" d="M3 15h10M8 15v-3M8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10ZM5.5 7h5"/></svg>',
    "view-full": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled" d="M5 5h6v6H5z"/><path class="stroke-linejoin-round" d="M2 2h12v12H2z"/></svg>',
    "view-horizontal": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled" d="M5 9h6v2H5z"/><path class="stroke-linejoin-round" d="M2 2h12v12H2z"/></svg>',
    "view-vertical": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><path class="filled" d="M11 5v6H9V5z"/><path class="stroke-linejoin-round" d="M2 2h12v12H2z"/></svg>',
    "zoom-in": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="6.885" cy="6.885" r="5.385"/><path d="m14.5 14.5-3.846-3.846M7 4v6M10 7H4"/></svg>',
    "zoom-out": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true"><circle cx="6.885" cy="6.885" r="5.385"/><path d="m14.5 14.5-3.846-3.846M10 7H4"/></svg>',
    "zoom-to-fit": '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 11v3h-4M2 11v3h4M2 5V2h4M14 5V2h-4M10 6H6v4h4V6Z"/></svg>'
};
