import "./styles.scoped.css";
export default {
    "root": "awsui_root_l0dv0_18rr4_93",
    "header": "awsui_header_l0dv0_18rr4_107",
    "header-link": "awsui_header-link_l0dv0_18rr4_112",
    "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_18rr4_120",
    "header-link-text": "awsui_header-link-text_l0dv0_18rr4_120",
    "header-logo": "awsui_header-logo_l0dv0_18rr4_124",
    "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_18rr4_130",
    "list-container": "awsui_list-container_l0dv0_18rr4_135",
    "list": "awsui_list_l0dv0_18rr4_135",
    "list-variant-root": "awsui_list-variant-root_l0dv0_18rr4_145",
    "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_18rr4_151",
    "list-item": "awsui_list-item_l0dv0_18rr4_155",
    "section": "awsui_section_l0dv0_18rr4_161",
    "expandable-link-group": "awsui_expandable-link-group_l0dv0_18rr4_162",
    "section-header": "awsui_section-header_l0dv0_18rr4_175",
    "link": "awsui_link_l0dv0_18rr4_180",
    "link-active": "awsui_link-active_l0dv0_18rr4_187",
    "info": "awsui_info_l0dv0_18rr4_219",
    "external-icon": "awsui_external-icon_l0dv0_18rr4_223",
    "divider": "awsui_divider_l0dv0_18rr4_227",
    "divider-default": "awsui_divider-default_l0dv0_18rr4_232",
    "divider-header": "awsui_divider-header_l0dv0_18rr4_236"
};
