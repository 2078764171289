import "./styles.scoped.css";
export default {
    "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_yshzd_97",
    "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_yshzd_1",
    "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_yshzd_124",
    "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_yshzd_1",
    "error": "awsui_error_14mhv_yshzd_97",
    "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_yshzd_1",
    "root": "awsui_root_14mhv_yshzd_168",
    "label": "awsui_label_14mhv_yshzd_180",
    "info": "awsui_info_14mhv_yshzd_193",
    "description": "awsui_description_14mhv_yshzd_198",
    "constraint": "awsui_constraint_14mhv_yshzd_199",
    "hints": "awsui_hints_14mhv_yshzd_206",
    "constraint-has-error": "awsui_constraint-has-error_14mhv_yshzd_207",
    "secondary-control": "awsui_secondary-control_14mhv_yshzd_211",
    "controls": "awsui_controls_14mhv_yshzd_215",
    "label-hidden": "awsui_label-hidden_14mhv_yshzd_215",
    "control": "awsui_control_14mhv_yshzd_215",
    "error__message": "awsui_error__message_14mhv_yshzd_234",
    "visually-hidden": "awsui_visually-hidden_14mhv_yshzd_238"
};
