import "./styles.scoped.css";
export default {
    "root": "awsui_root_1mabk_1y9l8_93",
    "radio": "awsui_radio_1mabk_1y9l8_105",
    "radio--has-description": "awsui_radio--has-description_1mabk_1y9l8_113",
    "radio-control": "awsui_radio-control_1mabk_1y9l8_117",
    "outline": "awsui_outline_1mabk_1y9l8_125",
    "styled-circle-border": "awsui_styled-circle-border_1mabk_1y9l8_144",
    "styled-circle-disabled": "awsui_styled-circle-disabled_1mabk_1y9l8_148",
    "styled-circle-fill": "awsui_styled-circle-fill_1mabk_1y9l8_153",
    "styled-circle-checked": "awsui_styled-circle-checked_1mabk_1y9l8_159"
};
