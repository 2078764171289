import "./styles.scoped.css";
export default {
    "link": "awsui_link_4c84z_1c30c_93",
    "variant-secondary": "awsui_variant-secondary_4c84z_1c30c_138",
    "variant-primary": "awsui_variant-primary_4c84z_1c30c_173",
    "variant-info": "awsui_variant-info_4c84z_1c30c_208",
    "variant-value-large": "awsui_variant-value-large_4c84z_1c30c_243",
    "variant-top-navigation": "awsui_variant-top-navigation_4c84z_1c30c_277",
    "variant-recovery": "awsui_variant-recovery_4c84z_1c30c_312",
    "button": "awsui_button_4c84z_1c30c_347",
    "color-inverted": "awsui_color-inverted_4c84z_1c30c_382",
    "font-size-body-s": "awsui_font-size-body-s_4c84z_1c30c_401",
    "font-size-body-m": "awsui_font-size-body-m_4c84z_1c30c_406",
    "font-size-heading-xs": "awsui_font-size-heading-xs_4c84z_1c30c_410",
    "font-size-heading-s": "awsui_font-size-heading-s_4c84z_1c30c_414",
    "font-size-heading-m": "awsui_font-size-heading-m_4c84z_1c30c_419",
    "font-size-heading-l": "awsui_font-size-heading-l_4c84z_1c30c_424",
    "font-size-heading-xl": "awsui_font-size-heading-xl_4c84z_1c30c_429",
    "font-size-display-l": "awsui_font-size-display-l_4c84z_1c30c_434",
    "font-size-inherit": "awsui_font-size-inherit_4c84z_1c30c_439",
    "icon-wrapper": "awsui_icon-wrapper_4c84z_1c30c_445",
    "icon": "awsui_icon_4c84z_1c30c_445"
};
