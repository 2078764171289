import "./styles.scoped.css";
export default {
    "root": "awsui_root_lm6vo_hzagk_97",
    "layout-wrapper": "awsui_layout-wrapper_lm6vo_hzagk_98",
    "root-no-scroll": "awsui_root-no-scroll_lm6vo_hzagk_104",
    "layout": "awsui_layout_lm6vo_hzagk_98",
    "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_hzagk_115",
    "layout-main": "awsui_layout-main_lm6vo_hzagk_119",
    "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_hzagk_125",
    "unfocusable": "awsui_unfocusable_lm6vo_hzagk_130",
    "navigation-panel": "awsui_navigation-panel_lm6vo_hzagk_134",
    "drawer": "awsui_drawer_lm6vo_hzagk_138",
    "drawer-mobile": "awsui_drawer-mobile_lm6vo_hzagk_144",
    "drawer-closed": "awsui_drawer-closed_lm6vo_hzagk_147",
    "opaque-background": "awsui_opaque-background_lm6vo_hzagk_154",
    "drawer-content": "awsui_drawer-content_lm6vo_hzagk_158",
    "toggle": "awsui_toggle_lm6vo_hzagk_183",
    "visual-refresh-toggle": "awsui_visual-refresh-toggle_lm6vo_hzagk_188",
    "visual-refresh-toggle-type-tools": "awsui_visual-refresh-toggle-type-tools_lm6vo_hzagk_192",
    "visual-refresh-toggle-type-navigation": "awsui_visual-refresh-toggle-type-navigation_lm6vo_hzagk_195",
    "button-toggles-container": "awsui_button-toggles-container_lm6vo_hzagk_205",
    "button-toggles-container-is-hidden": "awsui_button-toggles-container-is-hidden_lm6vo_hzagk_210",
    "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_hzagk_224",
    "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_hzagk_229",
    "content-wrapper": "awsui_content-wrapper_lm6vo_hzagk_233",
    "content-overlapped": "awsui_content-overlapped_lm6vo_hzagk_237",
    "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_hzagk_241",
    "navigation-toggle": "awsui_navigation-toggle_lm6vo_hzagk_246",
    "tools-toggle": "awsui_tools-toggle_lm6vo_hzagk_251",
    "navigation": "awsui_navigation_lm6vo_hzagk_134",
    "navigation-close": "awsui_navigation-close_lm6vo_hzagk_263",
    "tools": "awsui_tools_lm6vo_hzagk_251",
    "tools-close": "awsui_tools-close_lm6vo_hzagk_275",
    "notifications": "awsui_notifications_lm6vo_hzagk_279",
    "breadcrumbs": "awsui_breadcrumbs_lm6vo_hzagk_224",
    "content": "awsui_content_lm6vo_hzagk_229",
    "panel-wrapper-outer": "awsui_panel-wrapper-outer_lm6vo_hzagk_291",
    "panel-wrapper-inner": "awsui_panel-wrapper-inner_lm6vo_hzagk_292",
    "mobile": "awsui_mobile_lm6vo_hzagk_301",
    "open": "awsui_open_lm6vo_hzagk_307"
};
