import "./styles.scoped.css";
export default {
    "root": "awsui_root_1fj9k_avnjw_5",
    "navigation": "awsui_navigation_1fj9k_avnjw_9",
    "navigation-toggle": "awsui_navigation-toggle_1fj9k_avnjw_13",
    "navigation-close": "awsui_navigation-close_1fj9k_avnjw_17",
    "content": "awsui_content_1fj9k_avnjw_21",
    "notifications": "awsui_notifications_1fj9k_avnjw_25",
    "breadcrumbs": "awsui_breadcrumbs_1fj9k_avnjw_29",
    "tools": "awsui_tools_1fj9k_avnjw_33",
    "tools-close": "awsui_tools-close_1fj9k_avnjw_37",
    "tools-toggle": "awsui_tools-toggle_1fj9k_avnjw_41",
    "drawer-closed": "awsui_drawer-closed_1fj9k_avnjw_45",
    "mobile-bar": "awsui_mobile-bar_1fj9k_avnjw_49",
    "disable-body-scroll-root": "awsui_disable-body-scroll-root_1fj9k_avnjw_53"
};
