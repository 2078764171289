import "./styles.scoped.css";
export default {
    "column-layout": "awsui_column-layout_vj6p7_gvt2c_93",
    "grid": "awsui_grid_vj6p7_gvt2c_104",
    "grid-no-gutters": "awsui_grid-no-gutters_vj6p7_gvt2c_109",
    "grid-variant-text-grid": "awsui_grid-variant-text-grid_vj6p7_gvt2c_112",
    "grid-breakpoint-default": "awsui_grid-breakpoint-default_vj6p7_gvt2c_119",
    "grid-columns-1": "awsui_grid-columns-1_vj6p7_gvt2c_122",
    "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vj6p7_gvt2c_122",
    "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vj6p7_gvt2c_125",
    "grid-columns-2": "awsui_grid-columns-2_vj6p7_gvt2c_128",
    "grid-columns-3": "awsui_grid-columns-3_vj6p7_gvt2c_134",
    "grid-columns-4": "awsui_grid-columns-4_vj6p7_gvt2c_140",
    "grid-vertical-borders": "awsui_grid-vertical-borders_vj6p7_gvt2c_153",
    "grid-horizontal-borders": "awsui_grid-horizontal-borders_vj6p7_gvt2c_186",
    "root": "awsui_root_vj6p7_gvt2c_240",
    "tile-container": "awsui_tile-container_vj6p7_gvt2c_255",
    "has-metadata": "awsui_has-metadata_vj6p7_gvt2c_265",
    "selected": "awsui_selected_vj6p7_gvt2c_268",
    "disabled": "awsui_disabled_vj6p7_gvt2c_272",
    "columns": "awsui_columns_vj6p7_gvt2c_287",
    "column-1": "awsui_column-1_vj6p7_gvt2c_292",
    "breakpoint-xs": "awsui_breakpoint-xs_vj6p7_gvt2c_299",
    "breakpoint-xxs": "awsui_breakpoint-xxs_vj6p7_gvt2c_304",
    "column-2": "awsui_column-2_vj6p7_gvt2c_309",
    "column-3": "awsui_column-3_vj6p7_gvt2c_326",
    "column-4": "awsui_column-4_vj6p7_gvt2c_343",
    "control": "awsui_control_vj6p7_gvt2c_361",
    "no-image": "awsui_no-image_vj6p7_gvt2c_365",
    "image": "awsui_image_vj6p7_gvt2c_369"
};
