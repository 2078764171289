import "./styles.scoped.css";
export default {
    "button-dropdown": "awsui_button-dropdown_sne0l_1gwdq_93",
    "items-list-container": "awsui_items-list-container_sne0l_1gwdq_97",
    "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_sne0l_1gwdq_1",
    "rotate-up": "awsui_rotate-up_sne0l_1gwdq_114",
    "rotate-down": "awsui_rotate-down_sne0l_1gwdq_129",
    "header": "awsui_header_sne0l_1gwdq_144",
    "title": "awsui_title_sne0l_1gwdq_153",
    "description": "awsui_description_sne0l_1gwdq_154"
};
