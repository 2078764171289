import "./styles.scoped.css";
export default {
    "root": "awsui_root_18582_66aol_93",
    "child": "awsui_child_18582_66aol_97",
    "horizontal": "awsui_horizontal_18582_66aol_108",
    "horizontal-xxxs": "awsui_horizontal-xxxs_18582_66aol_112",
    "horizontal-xxs": "awsui_horizontal-xxs_18582_66aol_116",
    "horizontal-xs": "awsui_horizontal-xs_18582_66aol_120",
    "horizontal-s": "awsui_horizontal-s_18582_66aol_124",
    "horizontal-m": "awsui_horizontal-m_18582_66aol_128",
    "horizontal-l": "awsui_horizontal-l_18582_66aol_132",
    "horizontal-xl": "awsui_horizontal-xl_18582_66aol_136",
    "horizontal-xxl": "awsui_horizontal-xxl_18582_66aol_140",
    "child-horizontal-xxxs": "awsui_child-horizontal-xxxs_18582_66aol_145",
    "child-horizontal-xxs": "awsui_child-horizontal-xxs_18582_66aol_151",
    "child-horizontal-xs": "awsui_child-horizontal-xs_18582_66aol_157",
    "child-horizontal-s": "awsui_child-horizontal-s_18582_66aol_163",
    "child-horizontal-m": "awsui_child-horizontal-m_18582_66aol_169",
    "child-horizontal-l": "awsui_child-horizontal-l_18582_66aol_175",
    "child-horizontal-xl": "awsui_child-horizontal-xl_18582_66aol_181",
    "child-horizontal-xxl": "awsui_child-horizontal-xxl_18582_66aol_187",
    "vertical": "awsui_vertical_18582_66aol_196",
    "child-vertical-xxxs": "awsui_child-vertical-xxxs_18582_66aol_200",
    "child-vertical-xxs": "awsui_child-vertical-xxs_18582_66aol_204",
    "child-vertical-xs": "awsui_child-vertical-xs_18582_66aol_208",
    "child-vertical-s": "awsui_child-vertical-s_18582_66aol_212",
    "child-vertical-m": "awsui_child-vertical-m_18582_66aol_216",
    "child-vertical-l": "awsui_child-vertical-l_18582_66aol_220",
    "child-vertical-xl": "awsui_child-vertical-xl_18582_66aol_224",
    "child-vertical-xxl": "awsui_child-vertical-xxl_18582_66aol_228"
};
