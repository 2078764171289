import "./styles.scoped.css";
export default {
    "grid": "awsui_grid_14yj0_1ecf3_93",
    "no-gutters": "awsui_no-gutters_14yj0_1ecf3_107",
    "grid-column": "awsui_grid-column_14yj0_1ecf3_111",
    "colspan-1": "awsui_colspan-1_14yj0_1ecf3_120",
    "push-1": "awsui_push-1_14yj0_1ecf3_124",
    "pull-1": "awsui_pull-1_14yj0_1ecf3_127",
    "colspan-2": "awsui_colspan-2_14yj0_1ecf3_130",
    "push-2": "awsui_push-2_14yj0_1ecf3_134",
    "pull-2": "awsui_pull-2_14yj0_1ecf3_137",
    "colspan-3": "awsui_colspan-3_14yj0_1ecf3_140",
    "push-3": "awsui_push-3_14yj0_1ecf3_144",
    "pull-3": "awsui_pull-3_14yj0_1ecf3_147",
    "colspan-4": "awsui_colspan-4_14yj0_1ecf3_150",
    "push-4": "awsui_push-4_14yj0_1ecf3_154",
    "pull-4": "awsui_pull-4_14yj0_1ecf3_157",
    "colspan-5": "awsui_colspan-5_14yj0_1ecf3_160",
    "push-5": "awsui_push-5_14yj0_1ecf3_164",
    "pull-5": "awsui_pull-5_14yj0_1ecf3_167",
    "colspan-6": "awsui_colspan-6_14yj0_1ecf3_170",
    "push-6": "awsui_push-6_14yj0_1ecf3_174",
    "pull-6": "awsui_pull-6_14yj0_1ecf3_177",
    "colspan-7": "awsui_colspan-7_14yj0_1ecf3_180",
    "push-7": "awsui_push-7_14yj0_1ecf3_184",
    "pull-7": "awsui_pull-7_14yj0_1ecf3_187",
    "colspan-8": "awsui_colspan-8_14yj0_1ecf3_190",
    "push-8": "awsui_push-8_14yj0_1ecf3_194",
    "pull-8": "awsui_pull-8_14yj0_1ecf3_197",
    "colspan-9": "awsui_colspan-9_14yj0_1ecf3_200",
    "push-9": "awsui_push-9_14yj0_1ecf3_204",
    "pull-9": "awsui_pull-9_14yj0_1ecf3_207",
    "colspan-10": "awsui_colspan-10_14yj0_1ecf3_210",
    "push-10": "awsui_push-10_14yj0_1ecf3_214",
    "pull-10": "awsui_pull-10_14yj0_1ecf3_217",
    "colspan-11": "awsui_colspan-11_14yj0_1ecf3_220",
    "push-11": "awsui_push-11_14yj0_1ecf3_224",
    "pull-11": "awsui_pull-11_14yj0_1ecf3_227",
    "colspan-12": "awsui_colspan-12_14yj0_1ecf3_230",
    "push-12": "awsui_push-12_14yj0_1ecf3_234",
    "pull-12": "awsui_pull-12_14yj0_1ecf3_237",
    "push-0": "awsui_push-0_14yj0_1ecf3_240",
    "pull-0": "awsui_pull-0_14yj0_1ecf3_243",
    "offset-1": "awsui_offset-1_14yj0_1ecf3_246",
    "offset-2": "awsui_offset-2_14yj0_1ecf3_249",
    "offset-3": "awsui_offset-3_14yj0_1ecf3_252",
    "offset-4": "awsui_offset-4_14yj0_1ecf3_255",
    "offset-5": "awsui_offset-5_14yj0_1ecf3_258",
    "offset-6": "awsui_offset-6_14yj0_1ecf3_261",
    "offset-7": "awsui_offset-7_14yj0_1ecf3_264",
    "offset-8": "awsui_offset-8_14yj0_1ecf3_267",
    "offset-9": "awsui_offset-9_14yj0_1ecf3_270",
    "offset-10": "awsui_offset-10_14yj0_1ecf3_273",
    "offset-11": "awsui_offset-11_14yj0_1ecf3_276",
    "restore-pointer-events": "awsui_restore-pointer-events_14yj0_1ecf3_280"
};
