import "./styles.scoped.css";
export default {
    "layout": "awsui_layout_5gtk3_1onm4_99",
    "is-visual-refresh": "awsui_is-visual-refresh_5gtk3_1onm4_99",
    "background": "awsui_background_5gtk3_1onm4_102",
    "header": "awsui_header_5gtk3_1onm4_105",
    "is-overlap-disabled": "awsui_is-overlap-disabled_5gtk3_1onm4_115",
    "has-breadcrumbs": "awsui_has-breadcrumbs_5gtk3_1onm4_132",
    "content": "awsui_content_5gtk3_1onm4_141"
};
