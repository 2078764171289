import "./styles.scoped.css";
export default {
    "drawer": "awsui_drawer_1r9lg_8m9sl_189",
    "refresh": "awsui_refresh_1r9lg_8m9sl_189",
    "animating": "awsui_animating_1r9lg_8m9sl_203",
    "preference-icon--layout-border": "awsui_preference-icon--layout-border_1r9lg_8m9sl_226",
    "preference-icon--layout-background": "awsui_preference-icon--layout-background_1r9lg_8m9sl_230",
    "preference-icon--layout-header": "awsui_preference-icon--layout-header_1r9lg_8m9sl_233",
    "preference-icon--border": "awsui_preference-icon--border_1r9lg_8m9sl_236",
    "preference-icon--primary-button": "awsui_preference-icon--primary-button_1r9lg_8m9sl_240",
    "preference-icon--secondary": "awsui_preference-icon--secondary_1r9lg_8m9sl_243",
    "preference-icon--disabled-element": "awsui_preference-icon--disabled-element_1r9lg_8m9sl_246",
    "preference-icon--separator": "awsui_preference-icon--separator_1r9lg_8m9sl_249",
    "preference-icon--focus-text": "awsui_preference-icon--focus-text_1r9lg_8m9sl_252",
    "preference-icon-refresh--layout-top": "awsui_preference-icon-refresh--layout-top_1r9lg_8m9sl_256",
    "preference-icon-refresh--layout-main": "awsui_preference-icon-refresh--layout-main_1r9lg_8m9sl_259",
    "preference-icon-refresh--primary": "awsui_preference-icon-refresh--primary_1r9lg_8m9sl_262",
    "preference-icon-refresh--disabled": "awsui_preference-icon-refresh--disabled_1r9lg_8m9sl_265",
    "preference-icon-refresh--column-header": "awsui_preference-icon-refresh--column-header_1r9lg_8m9sl_268",
    "preference-icon-refresh--window": "awsui_preference-icon-refresh--window_1r9lg_8m9sl_271",
    "preference-icon-refresh--secondary": "awsui_preference-icon-refresh--secondary_1r9lg_8m9sl_275",
    "preference-icon-refresh--default": "awsui_preference-icon-refresh--default_1r9lg_8m9sl_278",
    "preference-icon-refresh--separator": "awsui_preference-icon-refresh--separator_1r9lg_8m9sl_281",
    "preference-icon-refresh--input-default": "awsui_preference-icon-refresh--input-default_1r9lg_8m9sl_284",
    "preference-icon-refresh--heading": "awsui_preference-icon-refresh--heading_1r9lg_8m9sl_287",
    "resize-active": "awsui_resize-active_1r9lg_8m9sl_292",
    "resize-side": "awsui_resize-side_1r9lg_8m9sl_298",
    "resize-bottom": "awsui_resize-bottom_1r9lg_8m9sl_301",
    "root": "awsui_root_1r9lg_8m9sl_306",
    "preferences-button": "awsui_preferences-button_1r9lg_8m9sl_317",
    "close-button": "awsui_close-button_1r9lg_8m9sl_321",
    "drawer-closed": "awsui_drawer-closed_1r9lg_8m9sl_332",
    "drawer-content-side": "awsui_drawer-content-side_1r9lg_8m9sl_337",
    "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_8m9sl_357",
    "position-bottom": "awsui_position-bottom_1r9lg_8m9sl_366",
    "position-side": "awsui_position-side_1r9lg_8m9sl_393",
    "slider": "awsui_slider_1r9lg_8m9sl_399",
    "slider-side": "awsui_slider-side_1r9lg_8m9sl_428",
    "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_8m9sl_433",
    "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_8m9sl_444",
    "open-button": "awsui_open-button_1r9lg_8m9sl_455",
    "open-button-side": "awsui_open-button-side_1r9lg_8m9sl_459",
    "slider-icon": "awsui_slider-icon_1r9lg_8m9sl_466",
    "slider-icon-bottom": "awsui_slider-icon-bottom_1r9lg_8m9sl_472",
    "slider-icon-side": "awsui_slider-icon-side_1r9lg_8m9sl_475",
    "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_8m9sl_479",
    "drawer-mobile": "awsui_drawer-mobile_1r9lg_8m9sl_489",
    "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_8m9sl_492",
    "content-bottom": "awsui_content-bottom_1r9lg_8m9sl_500",
    "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_8m9sl_511",
    "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_8m9sl_516",
    "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_8m9sl_520",
    "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_8m9sl_524",
    "content-side": "awsui_content-side_1r9lg_8m9sl_529",
    "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_8m9sl_540",
    "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_8m9sl_543",
    "header": "awsui_header_1r9lg_8m9sl_548",
    "header-text": "awsui_header-text_1r9lg_8m9sl_557",
    "header-divider": "awsui_header-divider_1r9lg_8m9sl_565",
    "header-actions": "awsui_header-actions_1r9lg_8m9sl_571",
    "divider": "awsui_divider_1r9lg_8m9sl_579"
};
