import "./styles.scoped.css";
export default {
    "item-element": "awsui_item-element_93a1u_1lzvl_93",
    "disabled": "awsui_disabled_93a1u_1lzvl_103",
    "last": "awsui_last_93a1u_1lzvl_110",
    "highlighted": "awsui_highlighted_93a1u_1lzvl_113",
    "variant-icon": "awsui_variant-icon_93a1u_1lzvl_117",
    "variant-normal": "awsui_variant-normal_93a1u_1lzvl_117",
    "variant-primary": "awsui_variant-primary_93a1u_1lzvl_117",
    "is-focused": "awsui_is-focused_93a1u_1lzvl_127",
    "variant-navigation": "awsui_variant-navigation_93a1u_1lzvl_131",
    "first": "awsui_first_93a1u_1lzvl_134",
    "has-category-header": "awsui_has-category-header_93a1u_1lzvl_134",
    "menu-item": "awsui_menu-item_93a1u_1lzvl_148",
    "icon": "awsui_icon_93a1u_1lzvl_166",
    "external-icon": "awsui_external-icon_93a1u_1lzvl_171"
};
