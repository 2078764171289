import "./styles.scoped.css";
export default {
    "dropdown-content-wrapper": "awsui_dropdown-content-wrapper_qwoo0_1aum0_93",
    "awsui-motion-fade-in-dropdown": "awsui_awsui-motion-fade-in-dropdown_qwoo0_1aum0_1",
    "refresh": "awsui_refresh_qwoo0_1aum0_114",
    "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_qwoo0_1aum0_1",
    "root": "awsui_root_qwoo0_1aum0_143",
    "interior": "awsui_interior_qwoo0_1aum0_155",
    "dropdown": "awsui_dropdown_qwoo0_1aum0_93",
    "use-portal": "awsui_use-portal_qwoo0_1aum0_166",
    "dropdown-drop-up": "awsui_dropdown-drop-up_qwoo0_1aum0_211",
    "with-limited-width": "awsui_with-limited-width_qwoo0_1aum0_215",
    "dropdown-drop-left": "awsui_dropdown-drop-left_qwoo0_1aum0_218",
    "dropdown-drop-right": "awsui_dropdown-drop-right_qwoo0_1aum0_221",
    "occupy-entire-width": "awsui_occupy-entire-width_qwoo0_1aum0_224",
    "hide-upper-border": "awsui_hide-upper-border_qwoo0_1aum0_227",
    "open": "awsui_open_qwoo0_1aum0_234",
    "nowrap": "awsui_nowrap_qwoo0_1aum0_243",
    "is-empty": "awsui_is-empty_qwoo0_1aum0_247",
    "dropdown-content": "awsui_dropdown-content_qwoo0_1aum0_93",
    "ie11-wrapper": "awsui_ie11-wrapper_qwoo0_1aum0_257",
    "stretch-trigger-height": "awsui_stretch-trigger-height_qwoo0_1aum0_261"
};
