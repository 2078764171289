import "./styles.scoped.css";
export default {
    "root": "awsui_root_2rhyz_crhmh_93",
    "input": "awsui_input_2rhyz_crhmh_97",
    "input-readonly": "awsui_input-readonly_2rhyz_crhmh_118",
    "input-invalid": "awsui_input-invalid_2rhyz_crhmh_179",
    "input-has-icon-left": "awsui_input-has-icon-left_2rhyz_crhmh_190",
    "input-type-search": "awsui_input-type-search_2rhyz_crhmh_195",
    "input-has-icon-right": "awsui_input-has-icon-right_2rhyz_crhmh_211",
    "input-has-no-border-radius": "awsui_input-has-no-border-radius_2rhyz_crhmh_214",
    "input-container": "awsui_input-container_2rhyz_crhmh_218",
    "input-icon": "awsui_input-icon_2rhyz_crhmh_223",
    "input-icon-hoverable": "awsui_input-icon-hoverable_2rhyz_crhmh_226",
    "input-icon-left": "awsui_input-icon-left_2rhyz_crhmh_230",
    "input-icon-right": "awsui_input-icon-right_2rhyz_crhmh_235"
};
