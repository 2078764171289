import {useEffect, useState} from "react";
import {
  Box,
  Button,
  FormField,
  Modal,
  SideNavigation,
  SideNavigationProps,
  SpaceBetween,
  Tiles
} from "@cloudscape-design/components-themed";
import Item = SideNavigationProps.Item;

export function Navigation() {

  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('comfortable');

  useEffect(() => {
    setValue('comfortable');
  }, []);

  const onFollowHandler = (e: CustomEvent<SideNavigationProps.FollowDetail>) => {
    // e.preventDefault();
    e.detail.href === '#/density_settings' ? setVisible(true) : null;
  };

  const onDismissHandler = () => {
    setValue('comfortable');
    setVisible(false);
  };

  const onSubmit = (value: any) => {
    setValue(value);
    setVisible(false);
  };

  const navItems: Item[] = [
    {type: 'link', text: 'Dashboard', href: '/'},
    {
      text: 'JSON Tools',
      type: 'section',
      defaultExpanded: true,
      items: [
        {type: 'link', text: 'Base64 to JSON', href: '/convert?from=base64&to=json'},
        {type: 'link', text: 'URL-escaped string to JSON', href: '/convert?from=urlencoded&to=json'},
        {type: 'link', text: 'JSON to Base64', href: '/convert?from=json&to=base64'},
        {type: 'link', text: 'JSON to URL-escaped string', href: '/convert?from=json&to=urlencoded',},
      ],
    },
    {
      text: 'String Tools',
      type: 'section',
      defaultExpanded: true,
      items: [
        {type: 'link', text: 'Reverse String', href: '/convert?from=text&to=reverse'},
      ],
    },
    {
      text: 'QR Code Tools',
      type: 'section',
      defaultExpanded: true,
      items: [
        {type: 'link', text: 'Generate QR Code', href: '/qr'},
        {type: 'link', text: 'Wi-Fi QR Code', href: '/qr/wifi'},
      ],
    }
  ];

  return (
    <>
      <SideNavigation items={navItems} activeHref="/" onFollow={e => onFollowHandler(e)}/>
      <Modal
        onDismiss={() => onDismissHandler()}
        visible={visible}
        size="medium"
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => onDismissHandler()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => onSubmit(value)}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Density settings"
      >
        <FormField
          label="Content density"
          description="Choose the preferred level of content density for this console."
        >
          <Tiles
            onChange={({detail}) => setValue(detail.value)}
            value={value}
            items={[
              {
                value: 'comfortable',
                label: 'Comfortable',
                description: 'Default spacing that optimizes information consumption.',
                // image: comfortableVisualRefreshImage,
              },
              {
                value: 'compact',
                label: 'Compact',
                description: 'Reduced spacing that provides more visibility over content.',
                // image: compactVisualRefreshImage,
              },
            ]}
          />
        </FormField>
      </Modal>
    </>
  );
}