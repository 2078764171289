import "./styles.scoped.css";
export default {
    "root": "awsui_root_1612d_9hw1o_127",
    "spinner-rotator": "awsui_spinner-rotator_1612d_9hw1o_1",
    "size-normal": "awsui_size-normal_1612d_9hw1o_135",
    "size-big": "awsui_size-big_1612d_9hw1o_143",
    "size-large": "awsui_size-large_1612d_9hw1o_151",
    "variant-normal": "awsui_variant-normal_1612d_9hw1o_159",
    "variant-disabled": "awsui_variant-disabled_1612d_9hw1o_162",
    "variant-inverted": "awsui_variant-inverted_1612d_9hw1o_165",
    "circle": "awsui_circle_1612d_9hw1o_169",
    "circle-left": "awsui_circle-left_1612d_9hw1o_193",
    "spinner-line-left": "awsui_spinner-line-left_1612d_9hw1o_1",
    "circle-right": "awsui_circle-right_1612d_9hw1o_198",
    "spinner-line-right": "awsui_spinner-line-right_1612d_9hw1o_1"
};
