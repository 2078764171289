import "./styles.scoped.css";
export default {
    "content": "awsui_content_1wepg_3hhd4_93",
    "description": "awsui_description_1wepg_3hhd4_94",
    "label": "awsui_label_1wepg_3hhd4_95",
    "outline": "awsui_outline_1wepg_3hhd4_99",
    "native-input": "awsui_native-input_1wepg_3hhd4_103",
    "wrapper": "awsui_wrapper_1wepg_3hhd4_110",
    "label-wrapper": "awsui_label-wrapper_1wepg_3hhd4_117",
    "empty-content": "awsui_empty-content_1wepg_3hhd4_129",
    "description-bottom-padding": "awsui_description-bottom-padding_1wepg_3hhd4_139",
    "label-disabled": "awsui_label-disabled_1wepg_3hhd4_147",
    "description-disabled": "awsui_description-disabled_1wepg_3hhd4_148",
    "control": "awsui_control_1wepg_3hhd4_153"
};
