import "./styles.scoped.css";
export default {
    "root": "awsui_root_14iqq_uvfcm_97",
    "variant-default": "awsui_variant-default_14iqq_uvfcm_110",
    "variant-stacked": "awsui_variant-stacked_14iqq_uvfcm_110",
    "header": "awsui_header_14iqq_uvfcm_133",
    "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_uvfcm_139",
    "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_uvfcm_143",
    "header-stuck": "awsui_header-stuck_14iqq_uvfcm_149",
    "header-dynamic-height": "awsui_header-dynamic-height_14iqq_uvfcm_154",
    "with-paddings": "awsui_with-paddings_14iqq_uvfcm_160",
    "with-hidden-content": "awsui_with-hidden-content_14iqq_uvfcm_163",
    "header-variant-cards": "awsui_header-variant-cards_14iqq_uvfcm_167",
    "header-variant-full-page": "awsui_header-variant-full-page_14iqq_uvfcm_191",
    "dark-header": "awsui_dark-header_14iqq_uvfcm_209",
    "content": "awsui_content_14iqq_uvfcm_213",
    "footer": "awsui_footer_14iqq_uvfcm_223",
    "with-divider": "awsui_with-divider_14iqq_uvfcm_229"
};
