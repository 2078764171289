import "./styles.scoped.css";
export default {
    "appbar": "awsui_appbar_hyvsj_ecp2y_93",
    "appbar-nav": "awsui_appbar-nav_hyvsj_ecp2y_117",
    "breadcrumbs": "awsui_breadcrumbs_hyvsj_ecp2y_121",
    "appbar-tools": "awsui_appbar-tools_hyvsj_ecp2y_125",
    "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_ecp2y_166",
    "has-notifications-content": "awsui_has-notifications-content_hyvsj_ecp2y_173",
    "has-header": "awsui_has-header_hyvsj_ecp2y_179",
    "has-dynamic-overlap-height": "awsui_has-dynamic-overlap-height_hyvsj_ecp2y_179",
    "content-type-wizard": "awsui_content-type-wizard_hyvsj_ecp2y_180",
    "content-type-cards": "awsui_content-type-cards_hyvsj_ecp2y_183",
    "content-type-table": "awsui_content-type-table_hyvsj_ecp2y_184",
    "has-sticky-background": "awsui_has-sticky-background_hyvsj_ecp2y_187",
    "background": "awsui_background_hyvsj_ecp2y_221",
    "notifications-appbar-header": "awsui_notifications-appbar-header_hyvsj_ecp2y_224",
    "sticky-notifications": "awsui_sticky-notifications_hyvsj_ecp2y_236",
    "overlap": "awsui_overlap_hyvsj_ecp2y_242",
    "content": "awsui_content_hyvsj_ecp2y_180",
    "layout": "awsui_layout_hyvsj_ecp2y_329",
    "has-max-content-width": "awsui_has-max-content-width_hyvsj_ecp2y_411",
    "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_ecp2y_438",
    "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_ecp2y_441",
    "has-sticky-notifications": "awsui_has-sticky-notifications_hyvsj_ecp2y_445",
    "has-split-panel": "awsui_has-split-panel_hyvsj_ecp2y_459",
    "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_ecp2y_459",
    "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_ecp2y_471",
    "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_ecp2y_474",
    "block-body-scroll": "awsui_block-body-scroll_hyvsj_ecp2y_489",
    "container": "awsui_container_hyvsj_ecp2y_497",
    "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_ecp2y_523",
    "is-navigation-open": "awsui_is-navigation-open_hyvsj_ecp2y_528",
    "is-tools-open": "awsui_is-tools-open_hyvsj_ecp2y_531",
    "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_ecp2y_531",
    "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_ecp2y_531",
    "content-type-default": "awsui_content-type-default_hyvsj_ecp2y_551",
    "content-type-form": "awsui_content-type-form_hyvsj_ecp2y_551",
    "unfocusable": "awsui_unfocusable_hyvsj_ecp2y_592",
    "navigation-container": "awsui_navigation-container_hyvsj_ecp2y_600",
    "show-navigation": "awsui_show-navigation_hyvsj_ecp2y_635",
    "animating": "awsui_animating_hyvsj_ecp2y_657",
    "showButtons": "awsui_showButtons_hyvsj_ecp2y_1",
    "navigation": "awsui_navigation_hyvsj_ecp2y_600",
    "openNavigation": "awsui_openNavigation_hyvsj_ecp2y_1",
    "animated-content": "awsui_animated-content_hyvsj_ecp2y_718",
    "hide-navigation": "awsui_hide-navigation_hyvsj_ecp2y_727",
    "notifications": "awsui_notifications_hyvsj_ecp2y_224",
    "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_ecp2y_792",
    "position-bottom": "awsui_position-bottom_hyvsj_ecp2y_838",
    "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_ecp2y_1",
    "split-panel-side": "awsui_split-panel-side_hyvsj_ecp2y_867",
    "position-side": "awsui_position-side_hyvsj_ecp2y_895",
    "openSplitPanelSide": "awsui_openSplitPanelSide_hyvsj_ecp2y_1",
    "tools-container": "awsui_tools-container_hyvsj_ecp2y_932",
    "tools": "awsui_tools_hyvsj_ecp2y_932",
    "openTools": "awsui_openTools_hyvsj_ecp2y_1",
    "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_ecp2y_1017",
    "hide-tools": "awsui_hide-tools_hyvsj_ecp2y_1027",
    "show-tools": "awsui_show-tools_hyvsj_ecp2y_1039",
    "has-tools-form": "awsui_has-tools-form_hyvsj_ecp2y_1017",
    "trigger": "awsui_trigger_hyvsj_ecp2y_1096",
    "selected": "awsui_selected_hyvsj_ecp2y_1135"
};
